import React, { ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import Close from '@/public/assets/icons/close.svg';
import { cn } from '@/utils/utils';

interface DialogProps {
  open: boolean;
  toggleOpen?: () => void;
  hideCloseButton?: boolean;
  trigger?: ReactNode;
  children?: ReactNode;
  className?: string;
  onOpenAutoFocus?: boolean;
}

export const Dialog = ({
  trigger,
  children,
  open,
  toggleOpen: setOpen,
  hideCloseButton = false,
  className,
  onOpenAutoFocus = false
}: DialogProps) => (
  <RadixDialog.Root open={open} onOpenChange={setOpen}>
    <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
    <RadixDialog.Portal>
      <RadixDialog.Overlay className="fixed inset-0 bg-common-black/50">
        <RadixDialog.Content
          onOpenAutoFocus={
            onOpenAutoFocus
              ? undefined
              : event => {
                  event.preventDefault();
                }
          }
          className={cn(
            'bg-blue-25',
            'fixed',
            'top-2/4',
            'left-2/4',
            'max-h-[90vh]',
            'h-auto',
            'translate-x-[-50%]',
            'translate-y-[-50%]',
            'p-10',
            'overflow-y-auto',
            'md:top-0',
            'md:left-0',
            'md:h-full',
            'md:max-h-none',
            'md:w-full',
            'md:max-w-none',
            'md:transform-none',
            className
          )}
        >
          {children}
          {!hideCloseButton && (
            <button className="absolute top-4 right-4 md:hidden" onClick={setOpen}>
              <Close />
            </button>
          )}
        </RadixDialog.Content>
      </RadixDialog.Overlay>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);
