import * as React from 'react';
import { useEffect, useState } from 'react';
import Select, { components, GroupBase, InputProps } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { cn } from '@/utils/utils';
import * as Label from '@radix-ui/react-label';
import globalLocale from '@/public/locales/nb/global.json';
import { InputSize } from '@/components/Input';
import { Typography } from '@/components/Typography';

interface DropDownIndicatorProps {
  disabled: boolean;
  hasError: boolean;
  isNonEmpty: boolean;
  inputSize?: InputSize;
}

const DropdownIndicatorComponent: React.FC<DropDownIndicatorProps> = ({
  disabled,
  hasError,
  isNonEmpty,
  inputSize
}) => (
  <div
    className={cn(
      'text-gray-80',
      {
        'text-gray-100': isNonEmpty && !disabled && !hasError,
        'active:text-gray-100': !disabled && !disabled && !hasError,
        'focus:text-gray-80': true
      },
      {
        'text-[1.125rem]': inputSize == InputSize.Medium || InputSize.Small || inputSize == undefined,
        'text-[1.5rem]': inputSize == InputSize.Large,
        'leading-normal': true
      }
    )}
  >
    ↓
  </div>
);

// Must be defined here to not cause rerender everytime of the component. Which in the case of the Input component
// will cause a bug where you can only write one letter at a time without losing focus
const IndicatorSeparator = () => null;
// eslint-disable-next-line react/display-name
const DropdownIndicator = (disabled: boolean, isNonEmpty: boolean, hasError: boolean) => () =>
  <DropdownIndicatorComponent disabled={disabled} isNonEmpty={isNonEmpty} hasError={hasError} />;
const Input = (props: InputProps<OptionType, false, GroupBase<OptionType>>) => (
  <components.Input {...props} isHidden={false} />
);

type OptionType = { label: string; value: string | number; disabled?: boolean };

type StringOptionType = OptionType & { value: string };

interface UncontrolledProps {
  id: string;
  disabled: boolean;
  required: boolean;
  loading: boolean;
  name: string;
  placeholder?: string;
  hasError: boolean;
  isNonEmpty: boolean;
  value: OptionType;
  onChange: (...event: any[]) => void;
  inputValue?: string;
  onInputChange?: (search: string) => void;
  options: OptionType[];
  noOptionsMessageEmptyInput?: string;
  loadingMessage?: string;
  inputSize?: InputSize;
  autoSelectIfOnlyOneOption: boolean;
  addMarginOnBottomOnMobile: boolean;
}

const UncontrolledInputSelect: React.FC<UncontrolledProps> = ({
  id,
  disabled,
  loading,
  name,
  required,
  placeholder,
  hasError,
  isNonEmpty,
  value,
  onChange,
  inputValue,
  onInputChange,
  options,
  noOptionsMessageEmptyInput = globalLocale.selectInput.noOptionsMessageEmptyInput,
  loadingMessage = globalLocale.selectInput.loadingMessage,
  inputSize,
  autoSelectIfOnlyOneOption,
  addMarginOnBottomOnMobile
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (onInputChange && value) {
      onInputChange(value.label.toString());
    } else if (onInputChange && !value) {
      onInputChange('');
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (value == undefined && autoSelectIfOnlyOneOption && options.length === 1 && !options[0].disabled) {
      onChange(options[0]);
    }
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuOpen = () => {
    setIsActive(true);
  };

  const handleMenuClose = () => {
    setIsActive(false);
  };

  const handleInputChange = (inputValue: string, { action }: { action?: string }) => {
    if (action === 'input-blur') {
      if (onInputChange) {
        onInputChange(value ? value.label : '');
      }
    }
    if (action === 'menu-close' || action === 'input-blur' || action === 'set-value') {
      return;
    }
    if (action === 'input-change') {
      setIsActive(true);
    }
    if (onInputChange) {
      onInputChange(inputValue);
    }
  };

  return (
    <Select
      name={name}
      id={id}
      inputId={'input-' + id}
      instanceId={'input-instance-' + id}
      options={options}
      menuShouldScrollIntoView
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onInputChange={handleInputChange}
      noOptionsMessage={({ inputValue }) => (!inputValue ? null : noOptionsMessageEmptyInput)}
      loadingMessage={({ inputValue }) => loadingMessage}
      isOptionDisabled={option => (option.disabled == undefined ? false : option.disabled)}
      styles={{
        control: styles => ({}),
        valueContainer: styles => ({}),
        input: styles => ({}),
        menu: styles => ({}),
        menuList: styles => ({}),
        option: styles => ({}),
        noOptionsMessage: styles => ({}),
        loadingMessage: styles => ({})
      }}
      classNames={{
        noOptionsMessage: state => cn('box-border', 'py-2', 'px-3', 'text-left', 'text-gray-100'),
        loadingMessage: state => cn('box-border', 'py-2', 'px-3', 'text-left', 'text-gray-100'),
        option: state =>
          cn(
            'block',
            '[font-size:inherit]',
            'w-full',
            'select-none',
            'px-3',
            'py-2',
            'box-border',
            'text-[1.125rem]',
            'text-common-black',
            {
              'text-gray-100': !state.isSelected && !state.isFocused
            },
            {
              'hover:bg-yellow-50': !state.isSelected && !state.isDisabled,
              'bg-yellow-50': state.isFocused,
              'bg-yellow-100': state.isSelected,
              'bg-gray-20': state.isDisabled
            }
          ),
        container: state =>
          cn('w-full', { [`md:mb-[300px]`]: state.selectProps.menuIsOpen && addMarginOnBottomOnMobile }),
        input: state =>
          cn(
            'm-0',
            'visible',
            'p-0',
            'inline-grid',
            '[grid-area:1/1/2/3]',
            '[grid-template-columns:0_min-content]',
            'box-border',
            '[transform:translateZ(0)]',
            'after:content-[attr(data-value)_"_"]',
            'after:invisible',
            'after:whitespace-pre',
            'after:[grid-area:1/2]',
            'after:border-0',
            'after:m-0',
            'after:outline-none',
            'after:p-0',
            'after:min-w-[2px]',
            'after:[font:inherit]'
          ),
        valueContainer: state =>
          cn('p-0', 'items-center', 'grid', 'flex-1', 'h-full', 'relative', 'overflow-hidden', 'box-border'),
        menuList: state => cn('p-0', 'max-h-[300px]', 'overflow-y-auto', 'relative', 'box-border'),
        menu: state =>
          cn(
            'top-full',
            // 'left: 0' is not needed in terms of placing the menu, BUT it is needed for the overflow to properly work
            // as it seems like not giving it proper coordinates and instead giving it percentages such as 100%
            // causes it to overflow its parent's body
            'left-0',
            'absolute',
            'z-[1]',
            'w-full',
            'shadow-common-black',
            'rounded-t-none',
            'bg-common-white',
            'border-[3px]',
            'border-solid',
            'border-common-black',
            'mt-2'
          ),
        control: state =>
          cn(
            // styles removed and reapplied from control
            'items-baseline',
            'flex',
            'flex-wrap',
            'justify-between',
            'outline-none',
            'relative',
            'transition-all',
            {
              'focus:rounded-b-none': state.menuIsOpen,
              'focus-within:rounded-b-none': state.menuIsOpen
            },
            'box-border',
            // Our styles
            'text-[1.125rem]',
            'leading-[130%]',
            'border-[3px]',
            'border-solid',
            'text-gray-100',
            'fill-gray-100',
            {
              'h-[4.5rem]': inputSize === InputSize.Large,
              'h-14': inputSize === InputSize.Medium,
              'h-12': inputSize === InputSize.Small || inputSize == undefined,
              'p-4': inputSize === InputSize.Large || InputSize.Medium,
              'py-2': inputSize === InputSize.Small || inputSize == undefined,
              'px-4': inputSize === InputSize.Small || inputSize == undefined,
              'md:py-2': inputSize === undefined,
              'md:px-4': inputSize === undefined,
              'md:h-12': inputSize == undefined
            },
            // normal
            {
              'border-gray-40': !disabled,
              'bg-common-white': !disabled,
              'hover(:not focus):border-gray-60': !disabled && !isActive
            },
            // active
            {
              'active:border-common-black': !disabled,
              'active:text-common-black': !disabled,
              'border-common-black': !disabled && isActive,
              'text-common-black': !disabled && isActive
            },
            // focus
            {
              'focus:border-system-focus': !disabled && !isActive,
              'focus-within:border-system-focus': !disabled && !isActive,
              'focus:bg-gray-10': !disabled && !isActive,
              'focus-within:bg-gray-10': !disabled && !isActive
            },
            // disabled
            {
              'border-gray-20': disabled,
              'bg-gray-20': disabled
            },
            // Error
            {
              'border-system-red-100': hasError,
              'bg-common-white': hasError
            },
            // Success
            {
              'border-green-100': isNonEmpty && !hasError && !isActive,
              'text-common-black': isNonEmpty && !hasError && !isActive,
              'fill-gray-100': isNonEmpty && !hasError && !isActive
            }
          )
      }}
      placeholder={placeholder}
      required={required}
      isDisabled={disabled}
      isLoading={loading}
      isSearchable={true}
      value={value == undefined ? null : value} // https://github.com/JedWatson/react-select/issues/3066
      onChange={value => {
        if (value) {
          handleInputChange(value.label, { action: undefined });
        }
        onChange(value);
      }}
      controlShouldRenderValue={inputValue == undefined}
      inputValue={inputValue}
      components={{
        IndicatorSeparator: IndicatorSeparator,
        DropdownIndicator: DropdownIndicator(disabled, isNonEmpty, hasError),
        Input: Input
      }}
    />
  );
};

interface Props {
  id: string;
  label?: string;
  helperText?: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  placeholder?: string;
  options: OptionType[];
  fullWidth?: boolean;
  onChange?: (...event: any[]) => void;
  inputValue?: string;
  onInputChange?: (search: string) => void;
  className?: string;
  noOptionsMessageEmptyInput?: string;
  noOptionsMessage?: string;
  loadingMessage?: string;
  autoSelectIfOnlyOneOption?: boolean;
  addMarginOnBottomOnMobile?: boolean;
}

const InputSelect: React.FC<Props> = ({
  id,
  name,
  disabled = false,
  loading = false,
  required = false,
  placeholder = '',
  options,
  fullWidth = false,
  label,
  helperText,
  onChange,
  inputValue,
  onInputChange,
  className,
  noOptionsMessageEmptyInput,
  loadingMessage,
  autoSelectIfOnlyOneOption = false,
  addMarginOnBottomOnMobile = false
}) => {
  const formContext = useFormContext();

  const hasError = formContext.formState.errors[name] != undefined;
  const isNonEmpty = formContext.watch(name) != undefined;

  return (
    <div
      className={cn(className, 'inline-flex', 'flex-col', 'items-start', 'gap-3', {
        'w-full': fullWidth
      })}
    >
      {label && (
        <Typography type="XXS-H" component={Label.Root} className={cn('text-common-black')} htmlFor={id}>
          {label}
        </Typography>
      )}
      <Controller
        control={formContext.control}
        name={name}
        render={({ field: { ref, ...rest }, formState }) => (
          <UncontrolledInputSelect
            disabled={disabled}
            loading={loading}
            required={required}
            placeholder={placeholder}
            hasError={hasError}
            isNonEmpty={isNonEmpty}
            options={options}
            id={id}
            inputValue={inputValue}
            onInputChange={onInputChange}
            noOptionsMessageEmptyInput={noOptionsMessageEmptyInput}
            loadingMessage={loadingMessage}
            autoSelectIfOnlyOneOption={autoSelectIfOnlyOneOption}
            addMarginOnBottomOnMobile={addMarginOnBottomOnMobile}
            {...rest}
            onChange={onChange ?? rest.onChange}
          />
        )}
      />
      {helperText && (
        <Typography type="XS-B" component={Label.Root} className={cn({ 'text-system-red-100': hasError })} htmlFor={id}>
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export { InputSelect };
export type { OptionType, StringOptionType, Props as InputSelectProps };
